<template>
  <div class="overview">
    <el-tabs v-model="activeName" @tab-click="handleTabChange">
      <el-tab-pane label="月度" name="month">
        <el-date-picker
          v-model="date"
          type="month"
          value-format="YYYY-MM"
          placeholder="请选择"
          @change="onChange"
        >
        </el-date-picker>
        <!-- 月度入库 -->
        <div v-if="currenData" class="stock">
          <div class="block_wrap">
            <div class="block_item totalMoney">
              <label>入库总金额：</label>
              <span>{{ currenData.totalMoney }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>入库总重量：</label>
              <span>{{ currenData.totalQuantity }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>入库总件数：</label>
              <span>{{ currenData.totalNumber }}</span>
            </div>
          </div>
          <div class="count">
            <el-table :data="currenData.typeCount" border class="type">
              <el-table-column prop="type" label="类别"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
            <el-table :data="currenData.producerCount" border class="supplier">
              <el-table-column prop="producerName" label="制袋人"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 月度出库 -->
        <div v-if="outboundData" class="outbound">
          <div class="block_wrap">
            <div class="block_item totalMoney">
              <label>出库总金额：</label>
              <span>{{ outboundData.totalMoney }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>出库总数量：</label>
              <span>{{ outboundData.totalQuantity }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>出库总件数：</label>
              <span>{{ outboundData.totalNumber }}</span>
            </div>
          </div>
          <div class="count">
            <el-table :data="outboundData.typeCount" border class="type">
              <el-table-column prop="type" label="类别"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
            <el-table :data="outboundData.buyerCount" border class="supplier">
              <el-table-column prop="buyerName" label="收货单位"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="年度" name="year">
        <el-date-picker
          v-model="yearDate"
          type="year"
          value-format="YYYY"
          placeholder="请选择"
          @change="onYearChange"
        >
        </el-date-picker>
        <!-- 年度入库 -->
        <div v-if="yearCurrenData" class="stock">
          <div class="block_wrap">
            <div class="block_item totalMoney">
              <label>入库总金额：</label>
              <span>{{ yearCurrenData.totalMoney }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>入库总重量：</label>
              <span>{{ yearCurrenData.totalQuantity }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>入库总件数：</label>
              <span>{{ yearCurrenData.totalNumber }}</span>
            </div>
          </div>
          <div class="count">
            <el-table :data="yearCurrenData.typeCount" border class="type">
              <el-table-column prop="type" label="类别"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
            <el-table :data="yearCurrenData.producerCount" border class="supplier">
              <el-table-column prop="producerName" label="制袋人"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 年度出库 -->
        <div v-if="yearOutboundData" class="outbound">
          <div class="block_wrap">
            <div class="block_item totalMoney">
              <label>出库总金额：</label>
              <span>{{ yearOutboundData.totalMoney }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>出库总重量：</label>
              <span>{{ yearOutboundData.totalQuantity }}</span>
            </div>
            <div class="block_item totalWeight">
              <label>出库总件数：</label>
              <span>{{ yearOutboundData.totalNumber }}</span>
            </div>
          </div>
          <div class="count">
            <el-table :data="yearOutboundData.typeCount" border class="type">
              <el-table-column prop="type" label="类别"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <el-table-column prop="money" label="总金额"></el-table-column>
              <el-table-column prop="number" label="总件数"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import dayjs from 'dayjs'
import { getSurplusApi, getOutboundCountApi } from './fetch'
export default {
  setup () {
    const state = reactive({
      yearDate: dayjs().format('YYYY'),
      date: dayjs().format('YYYY-MM'),
      company: {},
      activeName: 'month',
      currentDate: dayjs().format(),
      currenData: null,
      outboundData: null,
      yearCurrenData: null,
      yearOutboundData: null
    })

    onMounted(async () => {
      getSurplus()
      getOutboundCount()
    })

    const getSurplus = async () => {
      state.currenData = await getSurplusApi({
        date: state.date
      })
    }

    const getOutboundCount = async () => {
      state.outboundData = await getOutboundCountApi({
        date: state.date
      })
    }

    const getYearSurplus = async () => {
      state.yearCurrenData = await getSurplusApi({
        type: 2,
        date: state.yearDate
      })
    }

    const getYearOutboundCount = async () => {
      state.yearOutboundData = await getOutboundCountApi({
        type: 2,
        date: state.yearDate
      })
    }

    const handleTabClick = async (name) => {
      state.month = name.paneName
      await getSurplus()
    }

    const onChange = async () => {
      getSurplus()
      getOutboundCount()
    }

    const handleTabChange = (paneName) => {
      if (paneName === 'month') {
        getSurplus()
        getOutboundCount()
      } else {
        getYearSurplus()
        getYearOutboundCount()
      }
    }

    const onYearChange = () => {
      getYearSurplus()
      getYearOutboundCount()
    }

    return {
      ...toRefs(state),
      onChange,
      handleTabClick,
      handleTabChange,
      onYearChange
    }
  }
}
</script>

<style lang="stylus" scoped>
.overview {
  padding-top 10px !important
  .block_wrap {
    margin: 30px 0;
    padding: 0 10px;
    display: flex;

    .block_item {
      flex: 1;
      display: inline-block;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .count {
    display: flex;
    justify-content: space-between;

    .supplier {
      width: 48%;
    }

    .type {
      width: 48%;
    }
  }
}
</style>
