
import Request from '@/utils/request'

export function getSurplusApi (data) {
  return Request({
    url: '/product/stock/surplus',
    params: data
  })
}

export function getAllCompanyApi (data) {
  return Request({
    url: '/product/company/all'
  })
}

export function getOutboundCountApi (data) {
  return Request({
    url: '/product/outbound/count',
    params: data
  })
}
